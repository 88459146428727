import moment from "moment";
/*moment: https://momentjs.com/docs/#/displaying/ */

let _moment = moment("2017-01-01");

let dateParser = string => {
  if (moment(string).isValid()) {
    // change to local timezone display
    return moment(string)
      .local()
      .format("YYYY-MM-DD HH:mm");
  } else {
    return "";
  }
};

let parseSecToString = sec => {
  return _moment
    .startOf("day")
    .add(sec, "seconds")
    .format("H:mm:ss");
};

function d2s(date) {
  if (date) {
    // change to local timezone display
    return moment(date)
      .local()
      .format("YYYY-MM-DD");
  }
  return null;
}

function s2d(str) {
  if (str) {
    try {
      return moment(str).toDate();
    } catch (ex) {
      // bypass
    }
  }

  return null;
}

function dateFormat(str) {
  return moment(str).format("MMM DD, YYYY");
}

export { dateParser, parseSecToString, d2s, s2d, dateFormat };
