function jsonParser(jsonStr, defaultValue = "") {
  try {
    if (!jsonStr) {
      return defaultValue;
    }
    return JSON.parse(jsonStr);
  } catch (err) {
    return defaultValue;
  }
}

export default jsonParser;
