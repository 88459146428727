import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import * as Icon from "../Icon";
import Selectors from "../../Selectors";
import ActionCreator from "../../ActionCreator";
import CartItem from "../CartItem";
import * as Widget from "../Widget";

const Wrapper = styled.div`
  border: 1px solid #dadada;
  height: 100%;
  padding: 30px;
  @media screen and (max-width: 1200px) {
    padding: 20px;
  }

  & > .open-button {
    display: none;
    @media screen and (max-width: 1200px) {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      & > p {
        font-size: 16px;
        color: #575757;
      }
    }
  }

  & > .list-wrapper {
    border-bottom: 1px solid #dadada;
    @media screen and (max-width: 1200px) {
      padding-top: 30px;
      margin-top: 20px;
      border-top: 1px solid #dadada;
    }
  }

  & > .subtotal-wrapper {
    border-bottom: 1px solid #dadada;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0;

      & > p {
        font-size: 16px;
        color: #575757;
      }
    }
  }

  & > .total-wrapper {
    & > div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin: 30px 0 16px 0;

      & > p {
        font-size: 16px;
        color: #575757;
      }
    }
  }
`;

const SubscribeSection = styled.div`
  background-color: #b0ccb6;
  border-radius: 5px;
  padding: 20px;

  p {
    color: white;
  }
`;

class OrderBasket extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpened: this.props.openAsDefault,
      toSubscribe: false
    };
  }

  render() {
    let { isOpened, toSubscribe } = this.state;
    let {
      editMode,
      cartData,
      toConfigSubscribeData,
      subscribeData
    } = this.props;
    let { is_subscribe_order, exec_times } = subscribeData;

    if (!cartData) {
      return (
        <Widget.Center>
          <Widget.Spinner color="orange" />
        </Widget.Center>
      );
    }
    return (
      <Wrapper>
        <div
          className="open-button"
          onClick={() => this.setState({ isOpened: !isOpened })}
        >
          <p>購物清單</p>
          {isOpened ? (
            <Icon.ArrowUpward color="#575757" size={18} />
          ) : (
            <Icon.ArrowDownward color="#575757" size={18} />
          )}
        </div>
        {isOpened && (
          <Fragment>
            <div className="list-wrapper">
              {cartData.items.map((item, idx) => (
                <CartItem
                  key={idx}
                  data={item}
                  itemIndex={idx}
                  editMode={editMode}
                />
              ))}
            </div>
            <div className="subtotal-wrapper">
              <div>
                <p>商品小計</p>
                <p>{cartData.amount}元</p>
              </div>
            </div>
            <div className="total-wrapper">
              <div>
                <p>總計</p>
                <p>{cartData.amount}元</p>
              </div>
            </div>
          </Fragment>
        )}

        {cartData &&
          cartData.items.length === 1 &&
          cartData.items[0].type === "new" &&
          cartData.items[0].quantity === 1 &&
          window.location.pathname === `/cart` && (
            <SubscribeSection>
              <div style={{ display: "flex", alignItems: "center" }}>
                <input
                  type="checkbox"
                  ref="check_me"
                  style={{ marginRight: 10 }}
                  value={toSubscribe}
                  onClick={() => {
                    toConfigSubscribeData({
                      is_subscribe_order: this.refs.check_me.checked
                    });
                    this.setState({ toSubscribe: this.refs.check_me.checked });
                  }}
                />
                <p>訂閱此門號(定期定額)</p>
              </div>
              {toSubscribe && (
                <>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: 30,
                      marginTop: 10
                    }}
                  >
                    <div style={{ fontSize: 13, color: "#217aa2" }}>
                      訂閱期數：
                    </div>
                    <select
                      value={exec_times}
                      onChange={event =>
                        toConfigSubscribeData({
                          exec_times: event.target.value,
                          is_subscribe_order: true
                        })
                      }
                    >
                      {" "}
                      {this._renderOptions(cartData.items[0].period)}{" "}
                      {/* must be only one element when subscribe case */}
                    </select>
                  </div>
                  <p style={{ color: "#ff5722", fontSize: 12, margin: 10 }}>
                    注意：simcert將會根據您選擇的期數，每期進行信用卡扣款，如需取消此功能，請至“我的電話”頁面點選取消訂閱，客服人員將儘速為您處理
                  </p>
                </>
              )}
            </SubscribeSection>
          )}
      </Wrapper>
    );
  }

  _renderOptions = period => {
    if (period === "3m") {
      period = "90天";
    } else if (period === "6m") {
      period = "180天";
    } else if (period === "1y") {
      period = "365天";
    }
    let arr = [];
    for (let i = 2; i <= 9; i++) {
      arr.push(
        <option key={i} value={i}>
          {`${period}* ${i}期`}
        </option>
      );
    }
    return arr;
  };
}

OrderBasket.defaultProps = {
  openAsDefault: true
};

export default connect(
  null,
  ActionCreator
)(OrderBasket);
