import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import AppConfig from "../../Config";
import ActionCreator from "../../ActionCreator";
import * as Widget from "../Widget";

const Wrapper = styled.div`
  margin-bottom: 30px;
  position: relative;

  & > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    & .selectors {
      flex-direction: column;
      & > div {
        margin-top: 5px;
        display: flex;
        flex-direction: row;
      }
    }

    &.loading-mask {
      background-color: rgba(255, 255, 255, 0.6);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      position: absolute;
    }

    &.title-wrapper {
      margin-bottom: 12px;
    }

    & > .title {
      font-size: 16px;
      color: ${AppConfig["global"].themeColor};
    }

    & > .price {
      font-size: 16px;
      color: #575757;
    }

    & > .normal {
      font-size: 14px;
      color: #575757;
    }

    & > div {
      display: flex;
      flex-direction: row;
      align-items: flex-start;

      & > p {
        font-size: 14px;
        color: #575757;
      }
    }

    & > button {
      width: 48px;
      height: 30px;
      background-color: ${AppConfig["global"].themeColor};
      color: #fff;
      font-size: 14px;
      border: 0;
      cursor: pointer;
      outline: none;
    }
  }
`;

class CartItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  render() {
    let { loading } = this.state;
    let { data, editMode, appActions, itemIndex } = this.props;
    let { product, quantity, period, type, number, msg_qty } = data;
    return (
      <Wrapper editMode={editMode}>
        {loading && (
          <div className="loading-mask">
            <Widget.Spinner />
          </div>
        )}

        <div className="title-wrapper">
          <p className="title">{product.name}</p>
          {(() => {
            if (type == "ext_msg") {
              return (
                <p className="price">
                  {product[`msg_price_${msg_qty}`] * quantity}元
                </p>
              );
            } else if (type == "new") {
              return (
                <p className="price">
                  {product[`price_${period}`] * quantity}元
                </p>
              );
            } else if (type == "ext_dur") {
              return (
                <p className="price">
                  {product[`ext_price_${period}`] * quantity}元
                </p>
              );
            }
          })()}
        </div>

        {/* no promotions feature so far */}
        {/* <div> 
          {promotions.map((p, idx) => (
            <p key={idx} className="normal">
              {p.name} {this._getPromotionsDisplay(p)}
            </p>
          ))}
        </div> */}
        <div>
          <div className="selectors">
            {type == "ext_msg" && (
              <div>
                <p>加購簡訊數量</p>
                {editMode ? (
                  <Widget.SelectInput
                    ext_msg={true}
                    data={product}
                    value={msg_qty}
                    disabled={loading}
                    onChange={e => this._editItemExtendQty(e.target.value)}
                    extraCss="margin-left: 13px;"
                  />
                ) : (
                  <>
                    <p>-{msg_qty === "max" && `${product.ext_msg_max}則`}</p>
                    <p>{msg_qty === "med" && `${product.ext_msg_med}則`}</p>
                    <p>{msg_qty === "min" && `${product.ext_msg_min}則`}</p>
                  </>
                )}
              </div>
            )}
            {type != "ext_msg" && (
              <div>
                <p>{type == "ext_dur" ? `延長週期` : `門號週期`}</p>
                {editMode ? (
                  <Widget.SelectInput
                    value={period}
                    disabled={loading}
                    onChange={e => this._editItemPeriod(e.target.value)}
                    extraCss="margin-left: 13px;"
                  />
                ) : (
                  <>
                    <p>-{period === "3m" && `90天`}</p>
                    <p>{period === "6m" && `180天`}</p>
                    <p>{period === "1y" && `365天`}</p>
                  </>
                )}
              </div>
            )}

            {(type == "ext_dur" || type == "ext_msg") && (
              <p>電話號碼: {number}</p>
            )}
            {type == "new" && (
              <div>
                <p>數量</p>
                {editMode ? (
                  <Widget.QuantityInput
                    value={quantity}
                    disabled={loading}
                    onChange={e => this._editItemQuantity(e.target.value)}
                    extraCss="margin-left: 13px;"
                  />
                ) : (
                  <p>-{quantity}個</p>
                )}
              </div>
            )}
          </div>

          {editMode && (
            <button type="button" onClick={() => this._removeItemFromCart()}>
              刪除
            </button>
          )}
        </div>
      </Wrapper>
    );
  }

  _removeItemFromCart = () => {
    let { appActions, itemIndex } = this.props;
    this.setState({ loading: true });
    appActions
      .removeItemFromCart(itemIndex)
      .then(result => {
        this.setState({ loading: false });
      })
      .catch(err => 0);
  };

  _editItemQuantity = quantity => {
    let { appActions, itemIndex, data } = this.props;
    let { config, product, promotions, calculation } = data;
    this.setState({ loading: true });
    appActions
      .editItemFromCart({
        index: itemIndex,
        config: { ...data, quantity }
      })
      .then(result => {
        console.log("result after edit>>>", result);
        this.setState({ loading: false });
      })
      .catch(err => 0);
  };

  _editItemPeriod = period => {
    let { appActions, itemIndex, data } = this.props;
    this.setState({ loading: true });
    console.log("period>>>>>", period);
    appActions
      .editItemFromCart({
        index: itemIndex,
        config: { ...data, period }
      })
      .then(result => {
        this.setState({ loading: false });
      })
      .catch(err => console.warn(err));
  };

  _editItemExtendQty = msg_qty => {
    let { appActions, itemIndex, data } = this.props;
    this.setState({ loading: true });
    appActions
      .editItemFromCart({
        index: itemIndex,
        config: { ...data, msg_qty }
      })
      .then(result => {
        this.setState({ loading: false });
      })
      .catch(err => console.warn(err));
  };

  _getPromotionsDisplay = promotion => {
    switch (promotion.method) {
      case "DISCOUNT_BY_PRICE_X":
        return `折扣${promotion.x}元`;
      case "DISCOUNT_BY_PERCENT_X":
        return `${100 - promotion.x}折優惠`;
      case "BUY_X_GET_Y_FREE":
        return `買${promotion.x} 送${promotion.y}`;
      case "BUY_X_Y_TOGETHER_GET_DISCOUNT_Z":
        return `組合優惠`;
      default:
        return `符合折扣條件`;
    }
  };
}

export default connect(
  (state, ownProps) => ({}),
  ActionCreator
)(CartItem);
