import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import AppConfig from "../../Config";
import Constants from "../../Domain/constants";

const Wrapper = styled.a`
  background-color: ${AppConfig["global"].themeColor};
  width: 160px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-decoration: none;
  cursor: pointer;
  ${props => props.css}
`;

class PaymentButton extends React.Component {
  render() {
    let { order, css = "" } = this.props;
    return (
      <Wrapper css={css} onClick={() => this._checkOut(order)}>
        前往結帳
      </Wrapper>
    );
  }

  _checkOut = async order => {
    try {
      let respArr = await this._checkNumbersAvailable(order);
      let item_outofstock = respArr.filter(item => item.is_available === false);
      if (item_outofstock.length > 0) {
        //there are some new item, if any available = false, then block to redirect

        let items_name = item_outofstock.map(item => item.product.name);
        return (() => {
          window.alert(`產品 ${items_name} 沒有可購買的門號`);
          window.location.href = `${Constants.webUrl}`;
        })();
      }

      // all number available
      window.open(
        `${Constants.apiUrl}/checkout/request/?order=${
          order.id
        }&token=${localStorage.getItem("token")}`,
        ""
      );
    } catch (ex) {
      console.error(ex);
    }
  };

  _checkNumbersAvailable = async order => {
    let { appActions } = this.props;
    let result = await appActions.checkNumbersAvailable(order.id);

    return result;
  };
}

export default connect(
  (state, ownProps) => ({
    //
  }),
  ActionCreator
)(PaymentButton);
